.c-slider-pagination {
  position: static;
  margin: 20px auto 0;
  line-height: 0;
  max-width: 66vw;

  @include media-breakpoint-up(md) {
    margin: 25px auto 0;
  }

  .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    margin: 3px 6px !important; // stylelint-disable declaration-no-important
    background: #bdbdbd no-repeat center / contain;
    opacity: 1;
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path d="M7.5 0c.33 0 .63.22.72.54l.81 2.85a3.762 3.762 0 0 0 2.58 2.58l2.85.81a.75.75 0 0 1 0 1.44l-2.85.81a3.762 3.762 0 0 0-2.58 2.58l-.81 2.85a.75.75 0 0 1-1.44 0l-.81-2.85a3.762 3.762 0 0 0-2.58-2.58L.54 8.22a.75.75 0 0 1 0-1.44l2.85-.81a3.762 3.762 0 0 0 2.58-2.58L6.78.54A.75.75 0 0 1 7.5 0Z"/></svg>');

    &-active {
      background: $color-baika-pink no-repeat center / contain;
    }
  }
}
