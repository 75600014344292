.l-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;

  &__head {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
    pointer-events: none;

    @include media-breakpoint-up(md) {
      padding: 25px 30px;
    }
  }

  &__logo,
  &__cv {
    @include media-breakpoint-up(md) {
      transition: opacity 0.3s $ease-out-sine, visibility 0.3s $ease-out-sine;
    }
  }

  &__logo {
    pointer-events: auto;

    img {
      height: auto;

      @include media-breakpoint-up(md) {
        width: 336px;
      }
    }
  }

  &__block {
    margin: 0 0 0 auto;

    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
    }
  }

  &__cv {
    .c-primaryBtn {
      width: 260px;
      height: 66px;
      pointer-events: auto;

      &__txt {
        font-size: 17px;
      }
    }
  }

  &__menuButton {
    @include media-breakpoint-up(md) {
      margin: 0 0 0 25px;
    }

    .menuButton {
      position: relative;
      width: 56px;
      height: 56px;
      pointer-events: auto;
      background: $color-baika-pink;
      border-radius: 50%;
      transition: background-color 0.4s $ease-out-sine;

      @include media-breakpoint-up(md) {
        width: 80px;
        height: 80px;
      }

      @include hover() {
        &::before {
          opacity: 1;
          transform: scale(1.15);
        }
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        border: 2px solid $color-baika-pink;
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.4s $ease-out-sine, transform 0.4s $ease-out-sine;
      }

      &__line,
      &__close {
        position: absolute;
        top: 50%;
        left: 50%;
        transition: opacity 0.3s $ease-out-sine;
      }

      &__line {
        width: 18px;
        transform: translate(-50%, -50%);

        @include media-breakpoint-up(md) {
          width: 26px;
        }

        .line {
          width: 100%;
          height: 1px;
          background: #fff;
          transition: background-color 0.4s $ease-out-sine;

          + .line {
            margin: 7px 0 0;

            @include media-breakpoint-up(md) {
              margin: 11px 0 0;
            }
          }
        }
      }

      &__close {
        width: 27px;
        height: 27px;
        opacity: 0;
        transform: translate(-50%, -50%) rotate(45deg);

        &::before,
        &::after {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          height: 1px;
          content: '';
          background: #fff;
        }

        &::after {
          transform: rotate(90deg);
        }
      }
    }
  }

  &__body {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: scroll;
    pointer-events: none;
    visibility: hidden;
    background: #fcf1f1;
    opacity: 0;
    transition: opacity 0.3s $ease-out-sine, visibility 0.3s $ease-out-sine;

    @include media-breakpoint-up(md) {
      background: rgba(#000, 0.8);
    }
  }

  &__bodyWrapper {
    @include media-breakpoint-up(md) {
      background: #fcf1f1;
    }
  }

  &__bodyInner {
    padding: 90px 0 35px;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      padding: 40px 0 55px;
    }

    .navList {
      @include media-breakpoint-up(md) {
        display: flex;
        gap: 60px;
      }

      &__item {
        .nav {
          a[href] {
            @include a-hover-fade();
          }

          &__head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 700;
            letter-spacing: 0;

            @include media-breakpoint-up(md) {
              font-size: 14px;
            }

            .toggleIcon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 31px;
              height: 31px;
              background: #fff;
              border-radius: 50%;

              &__inner {
                position: relative;
                width: 13px;
                height: 1px;

                &::before,
                &::after {
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  content: '';
                  background: #333;
                }

                &::after {
                  transition: transform 0.3s $ease-out-sine;
                  transform: rotate(90deg);
                }
              }
            }

            &[aria-expanded='true'] {
              .toggleIcon {
                &__inner {
                  &::after {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }

          &__body {
            @include media-breakpoint-up(md) {
              display: block !important; /* stylelint-disable-line declaration-no-important */
              height: 100% !important; /* stylelint-disable-line declaration-no-important */
              overflow: visible !important; /* stylelint-disable-line declaration-no-important */
              visibility: visible !important; /* stylelint-disable-line declaration-no-important */
            }

            .childNav {
              margin: 15px 0 0;

              &__item {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0;

                @include media-breakpoint-up(md) {
                  font-size: 14px;
                }

                + .childNav__item {
                  margin: 10px 0 0;
                }
              }

              &--other {
                display: flex;
                flex-wrap: wrap;
                margin: -5px -12px 0;

                @include media-breakpoint-up(md) {
                  display: block;
                  margin: 0;
                }

                .childNav__item {
                  margin: 15px 12px 0;
                  font-size: 14px;

                  @include media-breakpoint-up(md) {
                    margin: 0;
                  }

                  + .childNav__item {
                    @include media-breakpoint-up(md) {
                      margin: 15px 0 0;
                    }
                  }
                }
              }
            }
          }

          + .nav {
            padding: 20px 0 0;
            margin: 20px 0 0;
            border-top: 1px solid rgba(#828282, 0.3);

            @include media-breakpoint-up(md) {
              padding: 0;
              margin: 25px 0 0;
              border-top: none;
            }
          }
        }

        + .navList__item {
          padding: 20px 0 0;
          margin: 20px 0 0;
          border-top: 1px solid rgba(#828282, 0.3);

          @include media-breakpoint-up(md) {
            padding: 0;
            margin: 0;
            border-top: none;
          }
        }
      }
    }

    .block {
      margin: 30px 0 0;

      @include media-breakpoint-up(md) {
        margin: 0 0 0 55px;
      }
    }

    .btnList {
      &__item {
        .c-primaryBtn {
          width: 100%;
          height: 84px;

          @include media-breakpoint-up(md) {
            width: 308px;
            height: 84px;
          }

          &__pamphletImg {
            position: absolute;
            bottom: 0;
            left: 15px;

            img {
              width: 75px;
            }
          }

          &--pamphletImg {
            .c-primaryBtn__txt {
              margin: 0 0 0 65px;
            }
          }
        }

        + .btnList__item {
          margin: 10px 0 0;
        }
      }
    }

    .contactList {
      margin: 30px 0 0;
      font-family: $font-serif;
      font-weight: 500;
      letter-spacing: 0;

      &__item {
        .ttl {
          font-size: 14px;
        }

        .num {
          font-size: 20px;
        }

        + .contactList__item {
          margin: 15px 0 0;
        }
      }
    }

    .snsList {
      padding: 25px 0 0;
      margin: 25px 0 0;
      border-top: 1px solid rgba(#828282, 0.3);

      @include media-breakpoint-up(md) {
        display: flex;
        gap: 50px;
        width: 100%;
        padding: 40px 0 0;
        margin: 40px 0 0;
      }

      &__item {
        .sns {
          display: flex;
          gap: 20px;
          align-items: center;

          @include media-breakpoint-up(md) {
            gap: 15px;
          }

          a[href] {
            @include a-hover-fade();
          }

          &__txt {
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 0;

            @include media-breakpoint-up(md) {
              font-size: 14px;
            }
          }
        }

        + .snsList__item {
          margin: 20px 0 0;

          @include media-breakpoint-up(md) {
            margin: 0;
          }
        }
      }
    }
  }

  &.is-menuOpen {
    .l-header__logo,
    .l-header__cv {
      visibility: hidden;
      opacity: 0;
    }

    .l-header__body {
      pointer-events: auto;
      visibility: visible;
      opacity: 1;
    }

    .l-header__menuButton {
      .menuButton {
        &__line {
          opacity: 0;
        }

        &__close {
          opacity: 1;
        }
      }
    }
  }
}
