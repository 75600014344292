.c-primaryBtn {
  position: relative;
  display: block;
  border-image-source: url('/assets/images/pages/common/compornent_border-frame.png');
  border-image-slice: 30;
  border-image-width: 15px;
  border-image-outset: 0;
  border-image-repeat: round;

  @include hover() {
    &::before {
      opacity: 1;
    }

    .c-primaryBtn__inner {
      &::before {
        background: $color-baika-pink;
      }
    }

    .c-primaryBtn__txt {
      color: #fff;
    }

    .c-primaryBtn__arw {
      &::before {
        background: #fff;
      }
    }
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    border-image-source: url('/assets/images/pages/common/compornent_border-frame-baikaPink.png');
    border-image-slice: 30;
    border-image-width: 15px;
    border-image-outset: 0;
    border-image-repeat: round;
    opacity: 0;
    transition: opacity 0.4s $ease-out-sine;
  }

  &__inner {
    position: absolute;
    inset: 5px;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      position: absolute;
      inset: 9px;
      z-index: -1;
      content: '';
      background: #fff;
      transition: background-color 0.4s $ease-out-sine;
    }
  }

  &__txt {
    font-family: $font-serif;
    font-weight: 500;
    letter-spacing: 0;
    transition: color 0.4s $ease-out-sine;
  }

  &__arw {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    margin: 0 0 0 5px;

    &::before {
      $width: 7px;
      $aspect: calc(11 / 7);

      width: $width;
      height: $width * $aspect;
      content: '';
      background: $color-baika-pink;
      transition: background-color 0.4s $ease-out-sine;
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
      mask-repeat: no-repeat;
    }
  }
}
