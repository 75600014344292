.c-slider-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  margin: -40px 0 0;
  background: rgba(#fff, 0.9);
  border-radius: 50%;
  box-shadow: 0 0 12px rgba(#000, 0.25);
  transition: opacity 0.4s, background-color 0.4s $ease-out-sine;

  @include media-breakpoint-up(md) {
    width: 70px;
    height: 70px;
    margin: -60px 0 0;
  }

  @include hover() {
    background: $color-baika-pink;

    &::before {
      background: #fff;
    }
  }

  &::before {
    width: 14px;
    height: 13px;
    content: '';
    background: $color-baika-pink no-repeat center / contain;
    transition: background-color 0.4s $ease-out-sine;
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 13"><g data-name="レイヤー_1"><path d="M7.168.197a.65.65 0 0 1 .932 0l5.707 5.827a.68.68 0 0 1 0 .952L8.1 12.803a.65.65 0 0 1-.932 0 .683.683 0 0 1 0-.952l4.58-4.678H.66C.295 7.173 0 6.872 0 6.5s.295-.673.66-.673h11.089L7.168 1.149a.683.683 0 0 1 0-.952Z"/></g></svg>');
    mask-repeat: no-repeat;
  }

  &.swiper-button-prev {
    &::before {
      transform: rotate(180deg);
    }
  }

  &::after {
    display: none;
  }

  &.swiper-button-disabled {
    opacity: 0;
  }
}
