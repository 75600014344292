html {
  min-width: map-get($grid-breakpoints, xs);

  @include media-breakpoint-up(md) {
    min-width: map-get($grid-breakpoints, xl);
  }
}

body {
  overflow: hidden;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 13px;
  font-feature-settings: 'palt' 1;
  color: #333;
  letter-spacing: 0.1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
}

a {
  color: currentColor;
  text-decoration: none;
}

img,
svg {
  vertical-align: middle;
}

ol,
ul {
  list-style: none;
}

// Eliminate extra whitespace caused by img element that inserted by access analysis script
body > img {
  display: block;
  // stylelint-disable-next-line declaration-no-important
  height: 0 !important;
  font-size: 0;
}

@include media-breakpoint-up(md) {
  a[href*="tel:"] {
    pointer-events: none;
    text-decoration: none;
  }
}
