.l-footer {
  &__contents {
    background: #fcf1f1;
  }

  &__main {
    padding: 40px 0;
    background: linear-gradient(90deg, #e76398, #e52d8a);

    @include media-breakpoint-up(md) {
      padding: 70px 0;
    }
  }

  &__mainInner {
    @include media-breakpoint-up(md) {
      display: flex;
    }

    .mainHead {
      .logo {
        img {
          width: 330px;
          height: auto;

          @include media-breakpoint-up(md) {
            width: 436px;
          }
        }
      }

      .address {
        margin: 15px 0 0;
        font-family: $font-serif;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        letter-spacing: 0;

        @include media-breakpoint-up(md) {
          margin: 25px 0 0;
        }
      }
    }

    .block {
      margin: 30px 0 0;

      @include media-breakpoint-up(md) {
        display: flex;
        margin: 0 0 0 auto;
      }
    }

    .contactList {
      font-family: $font-serif;
      font-weight: 500;
      color: #fff;
      letter-spacing: 0;

      &__item {
        .ttl {
          font-size: 16px;
        }

        .num {
          font-size: 24px;

          @include media-breakpoint-up(md) {
            cursor: text;
          }
        }

        + .contactList__item {
          padding: 20px 0 0;
          margin: 20px 0 0;
          border-top: 1px solid rgba(#fff, 0.3);
        }
      }
    }

    .btnList {
      margin: 40px 0 0;

      @include media-breakpoint-up(md) {
        margin: 0 0 0 55px;
      }

      &__item {
        .c-primaryBtn {
          width: 100%;
          height: 84px;

          @include media-breakpoint-up(md) {
            width: 308px;
            height: 84px;
          }

          @include hover() {
            &::before {
              border-image-source: url('/assets/images/pages/common/compornent_border-frame-baikaPink-white.png');
            }
          }

          &__pamphletImg {
            position: absolute;
            bottom: 0;
            left: 15px;

            img {
              width: 75px;
            }
          }

          &--pamphletImg {
            .c-primaryBtn__txt {
              margin: 0 0 0 65px;
            }
          }
        }

        + .btnList__item {
          margin: 10px 0 0;
        }
      }
    }
  }

  &__sub {
    padding: 30px 0 35px;
    background: #fcf1f1;

    @include media-breakpoint-up(md) {
      padding: 75px 0 105px;
    }

    .navList {
      gap: 90px;

      &__item {
        .nav {
          a[href] {
            @include a-hover-fade();
          }

          &__head {
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0;

            .sns {
              display: flex;
              gap: 20px;
              align-items: center;
            }
          }

          &__body {
            margin: 10px 0 0;

            .childNavList {
              &__item {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0;

                + .childNavList__item {
                  margin: 5px 0 0;
                }
              }
            }
          }

          + .nav {
            margin: 25px 0 0;
          }
        }
      }
    }

    .bnrList {
      display: flex;
      gap: 14px;
      justify-content: center;

      @include media-breakpoint-up(md) {
        gap: 32px;
        justify-content: flex-start;
        margin: 55px 0 0;
      }

      &__item {
        .bnr {
          @include a-hover-fade();

          img {
            width: 164px;
            height: auto;

            @include media-breakpoint-up(md) {
              width: 300px;
            }
          }
        }
      }
    }

    .linkList {
      margin: 35px 0 0;

      @include media-breakpoint-up(md) {
        margin: 65px 0 0;
      }

      &__item {
        display: inline;
        font-size: 12px;
        font-weight: 400;
        line-height: 2;
        letter-spacing: 0;

        @include media-breakpoint-up(md) {
          display: inline-block;
          font-size: 14px;
        }

        .link {
          @include a-hover-fade();
        }

        + .linkList__item {
          position: relative;
          padding: 0 0 0 17px;
          margin: 0 0 0 17px;

          &::before {
            position: absolute;
            top: 5px;
            left: 0;
            width: 1px;
            height: 12px;
            content: '';
            background: #333;

            @include media-breakpoint-up(md) {
              top: 7px;
              height: 15px;
            }
          }
        }
      }
    }
  }

  &__copyright {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    background: #fff;

    @include media-breakpoint-up(md) {
      height: 59px;
      font-size: 14px;
    }
  }
}
