.c-borderFrame {
  position: relative;
  z-index: 0;
  padding: 15px;

  &__outline,
  &__inline {
    position: absolute;

    .frameHorizontal,
    .frameVertical,
    .circleTop,
    .circleBottom {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__outline {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .frameHorizontal,
    .frameVertical {
      &::before,
      &::after {
        position: absolute;
        content: '';
        background: #fff;
      }
    }

    .frameHorizontal {
      &::before,
      &::after {
        right: 10px;
        left: 10px;
        height: 1px;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    .frameVertical {
      &::before,
      &::after {
        top: 10px;
        bottom: 10px;
        width: 1px;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    .circleTop,
    .circleBottom {
      &::before,
      &::after {
        position: absolute;
        width: 10px;
        height: 10px;
        content: '';
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    .circleTop {
      &::before,
      &::after {
        top: 0;
      }

      &::before {
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        border-radius: 0 0 100% 0;
      }

      &::after {
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        border-radius: 0 0 0 100%;
      }
    }

    .circleBottom {
      &::before,
      &::after {
        bottom: 0;
      }

      &::before {
        border-top: 1px solid #fff;
        border-right: 1px solid #fff;
        border-radius: 0 100% 0 0;
      }

      &::after {
        border-top: 1px solid #fff;
        border-left: 1px solid #fff;
        border-radius: 100% 0 0 0;
      }
    }
  }

  &__inline {
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;

    &::before {
      position: absolute;
      top: 10px;
      right: 10px;
      bottom: 10px;
      left: 10px;
      content: '';
      background: #fff;
    }

    .frameHorizontal,
    .frameVertical {
      &::before,
      &::after {
        position: absolute;
        content: '';
        background: #fff;
      }
    }

    .frameHorizontal {
      &::before,
      &::after {
        right: 10px;
        left: 10px;
        height: 10px;
      }

      &::before {
        top: 0;
      }

      &::after {
        bottom: 0;
      }
    }

    .frameVertical {
      &::before,
      &::after {
        top: 10px;
        bottom: 10px;
        width: 10px;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    .circleTop,
    .circleBottom {
      &::before,
      &::after {
        position: absolute;
        width: 10px;
        height: 10px;
        content: '';
        background: #fff no-repeat center / contain;
        mask-image: url('/assets/images/pages/common/compornent_border-frame.svg');
      }
    }

    .circleTop {
      &::before,
      &::after {
        top: 0;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
        transform: rotate(90deg);
      }
    }

    .circleBottom {
      &::before,
      &::after {
        bottom: 0;
      }

      &::before {
        transform: rotate(270deg);
      }

      &::after {
        right: 0;
        transform: rotate(180deg);
      }
    }
  }

  &__contents {
    position: relative;
  }

  &--bgFill {
    .c-borderFrame__outline {
      .frameHorizontal {
        &::before,
        &::after {
          height: 15px;
        }
      }

      .frameVertical {
        &::before,
        &::after {
          width: 15px;
        }
      }

      .circleTop,
      .circleBottom {
        &::before,
        &::after {
          position: absolute;
          width: 10px;
          height: 10px;
          content: '';
          background: url('/assets/images/pages/common/compornent_border-frame.svg')
            no-repeat center/contain;
          border: none;
          border-radius: 0;
        }
      }

      .circleTop {
        &::before,
        &::after {
          top: 0;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
          transform: rotate(90deg);
        }
      }

      .circleBottom {
        &::before,
        &::after {
          bottom: 0;
        }

        &::before {
          transform: rotate(270deg);
        }

        &::after {
          right: 0;
          transform: rotate(180deg);
        }
      }
    }

    .c-borderFrame__inline {
      top: 8px;
      right: 8px;
      bottom: 8px;
      left: 8px;

      &::before {
        background: #ffef9d;
      }

      .frameHorizontal,
      .frameVertical {
        &::before,
        &::after {
          background: #ffef9d;
        }
      }

      .circleTop,
      .circleBottom {
        &::before,
        &::after {
          background: #ffef9d no-repeat center / contain;
        }
      }
    }
  }
}
