.c-circleIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  background: $color-baika-pink;
  border-radius: 50%;

  @include media-breakpoint-up(md) {
    width: 52px;
    height: 52px;
  }

  &::before {
    $width: 7;
    $aspect: calc(12 / $width);

    display: block;
    width: #{$width}px;
    height: #{$width * $aspect}px;
    content: '';
    background: #fff no-repeat center / contain;
    mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
    mask-repeat: no-repeat;
  }
}
