@charset 'utf-8';

// prerequisites
@import 'abstracts/*';

// vendors
@import 'vendors/*';
@import '~ress/ress';
@import '~swiper/dist/css/swiper';
@import '~simplebar/dist/simplebar.min';
@import url('https://fonts.googleapis.com/css?family=Noto+Sans+JP');

// categorized rules
@import 'utils/*';
@import 'base/*';
@import 'layout/*';
@import 'components/*';
