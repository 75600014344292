.c-secondaryBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 20px 35px;
  font-family: $font-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  background: #fff;
  border-radius: 100px;
  transition: background-color 0.4s $ease-out-sine, color 0.4s $ease-out-sine;

  @include hover() {
    color: #fff;
    background: $color-baika-pink;

    .c-secondaryBtn__arw {
      &::before {
        background: #fff;
      }
    }
  }

  &__arw {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    margin: 2px 0 0 5px;

    &::before {
      display: block;
      width: 6.5px;
      height: 11px;
      content: '';
      background: $color-baika-pink;
      transition: background-color 0.4s $ease-out-sine;
      mask-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 6.5 11"><path d="M1 11c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l3.8-3.8L.3 1.7C-.1 1.3-.1.7.3.3s1-.4 1.4 0l4.5 4.5c.4.4.4 1 0 1.4l-4.5 4.5c-.2.2-.4.3-.7.3z"/></svg>');
      mask-repeat: no-repeat;
    }
  }
}
